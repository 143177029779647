<template>
  <CCard>
    <CCardHeader>
      <h2>All Users</h2>
    </CCardHeader>
    <CCardBody>
      <CDataTable :items="userList" :fields="usersFields" striped>
        <template #name="{ item }">
          <td>
            {{
              (item.firstName ? item.firstName : '') +
              (item.lastName ? ' ' + item.lastName : '')
            }}
          </td>
        </template>
        <template #systemRole="{ item }">
          <td>
            {{ displayRole(item) }}
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>
<script>
import { mapGetters } from 'vuex';

const USER_FIELDS = [
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'email',
    label: 'EMail',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'systemRole',
    label: 'System Role',
  },
];

export default {
  name: 'UserList',
  computed: {
    ...mapGetters('users', ['userList', 'loadingUsers', 'systemRoles']),
    usersFields() {
      return USER_FIELDS;
    },
  },
  methods: {
    displayRole(item) {
      let role = this.systemRoles.find((r) => r.value === item.systemRole);
      return role ? role.label + ' (' + role.value + ') ' : 'Unknown Role';
    },
    //   doDisable(user) {
    //     if (!user) return;
    //     if (!user.active) return;
    //     this.$store
    //       .dispatch('users/disableUser', user)
    //       .then(() => {
    //         this.$store.dispatch('users/all').then(() => {
    //           this.$swal.fire({
    //             icon: 'success',
    //             title: 'Disabled',
    //             text: 'This user has been successfully disabled.',
    //           });
    //         });
    //       })
    //       .catch(e => {
    //         console.error('error: ', { ...e });
    //         this.$swal.fire({
    //           icon: 'error',
    //           title: 'ERROR',
    //           text:
    //             'Unable to disable this user due the following server error: ' +
    //             e.message,
    //         });
    //       });
    //   },
    //   doEnable(user) {
    //     if (!user) return;
    //     if (user.active) return;
    //     this.$store
    //       .dispatch('users/enableUser', user)
    //       .then(() => {
    //         this.$store.dispatch('users/all').then(() => {
    //           this.$swal.fire({
    //             icon: 'success',
    //             title: 'Enabled',
    //             text: 'This user has been successfully enabled.',
    //           });
    //         });
    //       })
    //       .catch(e => {
    //         this.$swal.fire({
    //           icon: 'error',
    //           title: 'ERROR',
    //           text:
    //             'Unable to enable this user due the following server error: ' +
    //             e.message,
    //         });
    //       });
    //   },
    //   doDelete(user) {
    //     if (!user) return;
    //     this.$swal
    //       .fire({
    //         icon: 'question',
    //         title: 'Confirm Delete',
    //         text: `Are you sure you wish to permanently delete ${user.username}?`,
    //         okButtonText: 'Yes',
    //         showOkButton: true,
    //         cancelButtonText: 'No',
    //         showCancelButton: true,
    //       })
    //       .then(result => {
    //         if (result.value) {
    //           this.$store
    //             .dispatch('users/deleteUser', user)
    //             .then(() => {
    //               this.$store.dispatch('users/all').then(() => {
    //                 this.$swal.fire({
    //                   icon: 'success',
    //                   title: 'Deleted',
    //                   text: 'This user has been successfully deleted.',
    //                 });
    //               });
    //             })
    //             .catch(e => {
    //               this.$swal.fire({
    //                 icon: 'error',
    //                 title: 'ERROR',
    //                 text:
    //                   'Unable to delete this user due the following server error: ' +
    //                   e.message,
    //               });
    //             });
    //         }
    //       });
    //   },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch('users/all');
    });
  },
};
</script>
